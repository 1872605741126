import dayjs from "dayjs";

let cachedFirstSeenDate: dayjs.Dayjs | undefined;

export function setCachedFirstSeenDate(date: dayjs.Dayjs | undefined): void {
  cachedFirstSeenDate = date;
}

export function getCachedFirstSeenDate(): dayjs.Dayjs | undefined {
  return cachedFirstSeenDate;
}
