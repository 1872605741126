import styled from "@emotion/styled";
import Typography from "@material-ui/core/Typography";
import { useGoogleLogin } from "@react-oauth/google";
import * as React from "react";

import { useShowSnack } from "~/components/layouts/common/SnackLayout";
import { anonymousPost, authenticatedGet } from "~/utils/http";

type LoginFormValues = {
  access_token?: string | undefined;
};

export type LoginFormResult = {
  tokens: [string, string];
  user: IUser;
};

type LoginFormProps = {
  onPopUpOpen?: () => void;
  onSubmit: (result: LoginFormResult) => void;
  onPopUpClosed?: () => void;
};

export const LoginWithGoogle: React.FC<LoginFormProps> = ({
  onSubmit,
  onPopUpOpen,
  onPopUpClosed
}) => {
  const showSnack = useShowSnack();
  const errHandler = React.useCallback(
    (errString = "Error while logging in with Google") => {
      onPopUpClosed && onPopUpClosed();
      showSnack.error(errString);
    },
    [onPopUpClosed, showSnack]
  );

  const submitWrapper = React.useCallback(
    (values: LoginFormValues) =>
      anonymousPost("/api/v1/auth/jwt/create_google/", values)
        .then(response => {
          if (response.status === 200) {
            const tokens = [response.data.access, response.data.refresh] as [
              string,
              string
            ];
            return authenticatedGet(
              "/api/v1/account/me/",
              response.data.access,
              {}
            ).then(user => {
              onSubmit({ tokens, user });
            });
          } else if (response.status === 401) {
            errHandler(
              "You are not allowed to login using Google, please use your email and password."
            );
          } else {
            errHandler(`Http error ${response.status}`);
          }
        })
        .catch(err => {
          errHandler(err.toString() ?? "Unknown error");
        }),
    [onSubmit, errHandler]
  );

  const customLoginHandler = useGoogleLogin({
    onSuccess: resp => submitWrapper(resp),
    onError: () => errHandler(),
    onNonOAuthError: onPopUpClosed
  });

  const customButton = (
    <StyledButton
      type="button"
      onClick={() => {
        onPopUpOpen && onPopUpOpen();
        customLoginHandler();
      }}
    >
      <StyledTypography variant="caption">Sign in with Google</StyledTypography>
    </StyledButton>
  );

  return customButton;
};

const StyledTypography = styled(Typography)`
  font-size: 16px;
  min-height: 21px;
`;

const StyledButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 0.125rem solid #eaeaea;
  border-radius: 9999px;
  color: #171923;
  cursor: pointer;
  display: inline-flex;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 8px;
  padding: 13px 28px;
  position: relative;
  user-select: none;
  width: 100%;
  &:before {
    background-image: url("https://developers.google.com/identity/images/g-logo.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    content: "";
    height: 18px;
    left: 28px;
    position: absolute;
    width: 18px;
  }
`;
