import styled from "@emotion/styled";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { FormContext, useForm } from "react-hook-form";

import { LoginWithGoogle } from "~/components/auth/common/LoginWithGoogle";
import { FormTextField } from "~/components/core/FormTextField";
import LoadingIndicator from "~/components/core/LoadingIndicator";
import { useShowSnack } from "~/components/layouts/common/SnackLayout";
import { PEAR_PRIVACY_URL, PEAR_TERMS_URL } from "~/constants/api";
import ROUTES from "~/constants/routes";
import {
  anonymousPost,
  authenticatedGet,
  convertDrfErrors
} from "~/utils/http";

import AuthFormActions from "../common/AuthFormActions";

type LoginFormValues = {
  email: string;
  password: string;
};

export type LoginFormResult = {
  tokens: [string, string];
  user: IUser;
};

type LoginFormProps = {
  onSubmit: (result: LoginFormResult) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const form = useForm<LoginFormValues>({ mode: "onBlur" });
  const showSnack = useShowSnack();
  const [pendingGoogleLogin, setPendingGoogleLogin] = React.useState(false);
  const googleLoginHandler = (result: LoginFormResult) => {
    onSubmit(result);
  };
  const submitWrapper = React.useCallback(
    (values: LoginFormValues) =>
      anonymousPost("/api/v1/auth/jwt/create/", values)
        .then(response => {
          if (response.status === 200) {
            const tokens = [response.data.access, response.data.refresh] as [
              string,
              string
            ];
            return authenticatedGet(
              "/api/v1/account/me/",
              response.data.access,
              {}
            ).then(user => {
              onSubmit({ tokens, user });
            });
          } else if (response.status === 400) {
            form.setError(convertDrfErrors(response.data));
          } else if (response.status === 401) {
            showSnack.error("Invalid credentials");
          } else {
            showSnack.error(`Http error ${response.status}`);
          }
        })
        .catch(err => {
          showSnack.error(err.toString() ?? "Unknown error");
        }),
    [form, onSubmit, showSnack]
  );

  const renderGoogleLogin = () => {
    if (!process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID) {
      return null;
    }

    return (
      <>
        <LoginWithGoogle
          onSubmit={googleLoginHandler}
          onPopUpOpen={() => {
            setPendingGoogleLogin(true);
          }}
          onPopUpClosed={() => {
            setPendingGoogleLogin(false);
          }}
        />
        <StyledHR>
          <span>or</span>
        </StyledHR>
      </>
    );
  };

  return (
    <FormContext {...form}>
      <form
        className="flex flex-col justify-between h-full"
        onSubmit={form.handleSubmit(submitWrapper)}
      >
        <header className="h-[30px]">
          <h3 className="h2 mb-[20px]">Login Details</h3>
        </header>
        <div className="mt-[12px]" id="psi_sign_in" />
        {renderGoogleLogin()}
        <div className="flex flex-col gap-[16px]">
          <FormTextField
            name="email"
            label="email"
            type="email"
            fullWidth
            required="Email is a required field"
          />
          <FormTextField
            name="password"
            label="password"
            type="password"
            fullWidth
            required="Password is a required field"
          />
        </div>
        <Typography
          variant="caption"
          className="py-[16px] explanatoryText !text-[12px]"
        >
          Clicking Login means you are agreeing to the updated Terms and Privacy
          Policy
        </Typography>
        <AuthFormActions
          mainAction={{
            caption: "Login",
            disabled: form.formState.isSubmitting
          }}
          secondaryAction={{
            caption: "Forgot password?",
            href: ROUTES.auth.passwordReset
          }}
        />
        <div className="flex justify-start gap-[16px] pt-[24px]">
          <Typography
            component="a"
            variant="caption"
            target="_blank"
            href={PEAR_PRIVACY_URL}
            className="explanatoryTextLink"
          >
            Product Privacy Policy
          </Typography>
          <Typography
            component="a"
            variant="caption"
            target="_blank"
            href={PEAR_TERMS_URL}
            className="explanatoryTextLink"
          >
            Product Terms of Service
          </Typography>
        </div>
      </form>
      {(form.formState.isSubmitting || pendingGoogleLogin) && (
        <LoadingIndicator />
      )}
    </FormContext>
  );
};

const StyledHR = styled.div`
  align-items: center;
  display: flex;
  text-align: center;

  ::before,
  ::after {
    border-bottom: 1px solid;
    content: "";
    flex: 1;
  }

  ::before {
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    margin-right: 0.5em;
  }

  ::after {
    border-color: rgba(0, 0, 0, 0.2);
    margin-left: 0.5em;
  }

  span {
    padding: 0 0.5em;
  }
`;
