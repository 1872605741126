export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;

export enum DATE_UNIT {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  ms = "ms"
}

export const DATE_FORMAT = "MM/DD/YY";
export const DATE_VIEW_FORMAT = "MM/DD/YY | hh:mm A";
export const DATE_VIEW_FORMAT_MOBILE = "MM/DD/YY hh:mm A";
export const DATE_DASH_VIEW_FORMAT = "MM-DD-YY | hh:mm A";
export const MONTH_FORMAT = "MMM YYYY";
export const MONTH_FULL_FORMAT = "MMMM YYYY";
export const DAY_FORMAT = "D";

export enum PERIOD {
  last7Days = "last7Days",
  last30Days = "last30Days",
  last90Days = "last90Days",
  last365Days = "last365Days",
  allTime = "allTime",
  currentYear = "currentYear",
  previousYear = "previousYear",
  custom = "custom"
}

export const PERIODS = [
  { period: PERIOD.last7Days, title: "Last 7 days" },
  { period: PERIOD.last30Days, title: "Last 30 days" },
  { period: PERIOD.last90Days, title: "Last 90 days" },
  { period: PERIOD.last365Days, title: "Last 365 days" },
  { period: PERIOD.allTime, title: "All Time" },
  { period: PERIOD.currentYear, title: "Current school year" },
  { period: PERIOD.previousYear, title: "Previous school year" },
  { period: PERIOD.custom, title: "Custom" }
];

export const STATIC_VARIANTS: Array<{
  label: string;
  active: boolean;
  period: PERIOD;
}> = [{ label: "Custom", active: false, period: PERIOD.custom }];

export const MONTH_OPTIONS = [
  { value: "0", label: "January" },
  { value: "1", label: "February" },
  { value: "2", label: "March" },
  { value: "3", label: "April" },
  { value: "4", label: "May" },
  { value: "5", label: "June" },
  { value: "6", label: "July" },
  { value: "7", label: "August" },
  { value: "8", label: "September" },
  { value: "9", label: "October" },
  { value: "10", label: "November" },
  { value: "11", label: "December" }
];
