import dayjs from "dayjs";

import { DATE_UNIT } from "~/constants/dates";
import { Range } from "~/declarations/filters";

export function getSchoolYearStart(month: number, day: number): string {
  const today = dayjs();
  const schoolYrStart = dayjs()
    .month(month)
    .date(day);

  const startYear = today.isBefore(schoolYrStart)
    ? dayjs().year() - 1
    : dayjs().year();

  return dayjs(new Date(startYear, month, day)).toISOString();
}

export const schoolYearFilterLabel = (label: string, date: string): string => {
  const schoolYearStartDate = dayjs(date);
  if (label === "Current school year") {
    return `${label} ('${schoolYearStartDate.format(
      "YY"
    )} - '${schoolYearStartDate.add(1, DATE_UNIT.year).format("YY")})`;
  } else if (label === "Previous school year") {
    return `${label} ('${schoolYearStartDate
      .subtract(1, DATE_UNIT.year)
      .format("YY")} - '${schoolYearStartDate.format("YY")})`;
  } else {
    return label;
  }
};

export const getSchoolYearRange = (
  date: string,
  isCurrentYear: boolean,
  is2YrAgo?: boolean
): Range => {
  const schoolYearStartDate = dayjs(date);
  const fromDt = isCurrentYear
    ? schoolYearStartDate.startOf(DATE_UNIT.day)
    : is2YrAgo
    ? schoolYearStartDate.subtract(2, DATE_UNIT.year).startOf(DATE_UNIT.day)
    : schoolYearStartDate.subtract(1, DATE_UNIT.year).startOf(DATE_UNIT.day);

  const tillDt = isCurrentYear
    ? fromDt.add(1, DATE_UNIT.year).subtract(1, DATE_UNIT.ms)
    : is2YrAgo
    ? schoolYearStartDate.subtract(1, DATE_UNIT.year).subtract(1, DATE_UNIT.ms)
    : schoolYearStartDate.startOf(DATE_UNIT.day).subtract(1, DATE_UNIT.ms);
  return [fromDt, tillDt];
};
