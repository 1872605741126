let cached: WeekStart | undefined;

export function getCachedWeekStart(): WeekStart {
  if (typeof cached === "undefined") {
    cached = 0;
  }
  return cached;
}

export function setCachedWeekStart(value: WeekStart): void {
  cached = value;
}
